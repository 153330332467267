import React, { FC, MouseEventHandler } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface CategoryCardProps {
  icon: string;
  detail: string;
  onClick?: MouseEventHandler;
  slug?: string;
}

export const CategoryCard: FC<CategoryCardProps> = ({
  icon,
  detail,
  onClick,
}) => {
  return (
    <div className="xvia-category-card" onClick={onClick}>
      <FontAwesomeIcon
        className="xvia-category-card__icon"
        icon={`fa-solid ${icon}` as IconProp}
      />
      <div>
        <p className="xvia-metrics-card__detail">{detail}</p>
      </div>
    </div>
  );
};
