import React, { FC } from "react";
import { Paragraph } from "../../components/typography/paragraph";
import { Col, Row } from "antd";
import {
  MetricsCard,
  MetricsCardProps,
} from "../../components/cards/metrics-card";
import { Container } from "../../components/container/container";

export interface IndicatorsProps {
  metrics?: MetricsCardProps[];
}

export const Indicators: FC<IndicatorsProps> = ({ metrics }) => {
  return (
    <div className="xvia-indicators">
      <Container className="xvia-indicators__container">
        <div>
          <Row justify="center" gutter={[15, 34]}>
            <Col
              className="xvia-indicators__card"
              xs={24}
              sm={11}
              md={11}
              lg={8}
              xl={8}
              xxl={8}
            >
              <Paragraph className="xvia-indicators__subtitle">
                Fatos e
              </Paragraph>
              <h2 className="xvia-indicators__title">Indicadores</h2>
            </Col>
            {metrics?.map((metric) => {
              return (
                <Col
                  className="xvia-indicators__card"
                  xs={24}
                  sm={11}
                  md={11}
                  lg={6}
                  xl={6}
                  xxl={6}
                >
                  <MetricsCard
                    text={metric.text}
                    icon={metric.icon}
                    detail={metric.detail}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </div>
  );
};
