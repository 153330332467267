import React, { FC, useEffect, useMemo, useState } from "react";
import { Container } from "../../components/container/container";
import { Paragraph } from "../../components/typography/paragraph";
import { Col, Pagination, Radio, Row, Space } from "antd";
// @ts-ignore
import { IDepartment, ISection } from "../../apis/department";
import { Catalog } from "../../apis/search-catalog";
import { ServiceCard } from "../../components/cards/service-card";
import { SidebarCard } from "../../components/cards/sidebar-card";
import { SessionCard } from "../../components/cards/session-card";
import { isValidUrl } from "../../util/url";
import { compareObjectsByProperty } from "../../util/compare-objects-by-property";

function stringToHTML(text: string) {
  return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
}

export interface DepartmentProps extends IDepartment {
  section?: ISection[];
  catalogList?: Catalog[];
  onClickCatalog: (slug: string, categorySlug: string) => void;
  currentPage?: number;
  resultTotal?: number;
  onPaginate?: (page: number) => void;
  departmentServicesRef?: React.Ref<HTMLDivElement>;
}

export const Department: FC<DepartmentProps> = ({
  name,
  shortName,
  mission,
  vision,
  values,
  slug,
  section,
  catalogList,
  onClickCatalog,
  link,
  currentPage,
  resultTotal,
  onPaginate,
  departmentServicesRef,
}) => {
  const [session, setSession] = useState<any>();

  useEffect(() => {
    if (section && section[0]) {
      setSession(section[0]);
    }
  }, [section]);

  const catalogs = useMemo(() => {
    return catalogList?.sort(compareObjectsByProperty('title'))
  }, [catalogList])

  return (
    <div className="xvia-department">
      <div className={"xvia-list__head_content"}>
        <div
          className={"xvia-list__head_info"}
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/${slug || "department-default-head-bg"
              }.jpg`,
          }}
        />
        <Container className={"xvia-list__head_info_container"}>
          <Paragraph className={"xvia-list__head_info__subtitle"}>
            {name}
          </Paragraph>
          <h2 className={"xvia-list__head_info__title"}>{shortName}</h2>
        </Container>
        <div className="xvia-home-search__gradient"></div>
      </div>
      <Container className="xvia-department__container">
        <div className=""></div>
        <Row gutter={20}>
          <Col xs={24} sm={15} md={15} lg={15} xl={15} xxl={15}>
            <div className="xvia-department__info_section">
              <h2>Missão </h2>
              <Paragraph>
                {mission ? stringToHTML(mission) : "Não informado"}
              </Paragraph>
            </div>
            <div className="xvia-department__info_section">
              <h2>Visão </h2>
              <Paragraph>
                {vision ? stringToHTML(vision) : "Não informado"}
              </Paragraph>
            </div>
            <div className="xvia-department__info_section">
              <h2>Valores </h2>
              <Paragraph>
                {values ? stringToHTML(values) : "Não informado"}
              </Paragraph>
            </div>
          </Col>
          <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
            <div className="xvia-department__info_section">
              {isValidUrl(link) && (
                <SidebarCard
                  title={"Site do órgão"}
                  className="xvia-card__borderless-bottom"
                >
                  <Paragraph>
                    <a href={link} target="_blank" rel="noreferrer">
                      {link}
                    </a>
                  </Paragraph>
                </SidebarCard>
              )}
              <SidebarCard title={"Unidades de atendimento"}>
                {!!section?.length ? (
                  <div>
                    <Paragraph>
                      {section?.length === 1
                        ? `1 unidade que presta o serviço:`
                        : `${section?.length} unidades que prestam o serviço:`}
                    </Paragraph>
                    <Radio.Group
                      onChange={(value) => {
                        setSession(value.target.value);
                      }}
                      value={session}
                      className="xvia-catalog__section_list"
                    >
                      <Space direction="vertical">
                        {section?.map((session) => {
                          return <Radio value={session}>{session.name}</Radio>;
                        })}
                      </Space>
                    </Radio.Group>
                  </div>
                ) : (
                  <Paragraph style={{ padding: 20, textAlign: "center" }}>
                    Nenhuma unidade disponível para este serviço
                  </Paragraph>
                )}

                {session && (
                  <SessionCard
                    mapUrl={session?.mapUrl}
                    name={session?.name}
                    openingTime={session?.openingTime}
                    telephone={session?.telephone}
                    email={session?.email}
                    city={session?.city}
                    address={session?.address}
                    district={session?.district}
                    state={session?.state}
                    complement={session?.complement}
                  />
                )}
              </SidebarCard>
            </div>
          </Col>
        </Row>

        {!!catalogs?.length && (
          <div
            className="xvia-department__info_section"
            ref={departmentServicesRef}
          >
            <h2>Serviços prestados</h2>
            <Row gutter={[40, 40]}>
              {catalogs?.map((catalog) => {
                return (
                  <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <ServiceCard
                      title={catalog.title}
                      categoryIcon={catalog.icon}
                      targets={catalog?.targets}
                      type={catalog.isOnline ? "online" : "in-person"}
                      link={`/${catalog.categorySlug}/${catalog.slug}`}
                    />
                  </Col>
                );
              })}
            </Row>
            <div className="xvia-department__pagination">
              <Pagination
                current={currentPage}
                total={resultTotal}
                onChange={(page) => {
                  onPaginate?.(page ? page - 1 : 0);
                }}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};