import React, { FC } from "react";
import { Col, Row } from "antd";
import { LinkList, LinkListProps } from "../../components/link-list/link-list";
import {
  IMidia,
  SocialMidia,
} from "../../components/social-midia/social-midia";
import { Container } from "../../components/container/container";

export interface FooterProps {
  lists: LinkListProps[];
  social: IMidia[];
}

export const Footer: FC<FooterProps> = ({ lists, social }) => {
  return (
    <div className="xvia-footer">
      <Container className="xvia-footer__container">
        <div>
          <Row justify="center" gutter={[15, 34]}>
            <Col
              className="xvia-footer__card"
              xs={24}
              sm={11}
              md={11}
              lg={4}
              xl={4}
              xxl={4}
            >
              <div>
                <img
                  className={"xvia-footer__logo"}
                  src={"/assets/img/logo/logo-white.png"}
                  alt={"logo"}
                />
              </div>
              <SocialMidia midiasList={social} />
              <div>
                <a
                  href={"http://www.mti.mt.gov.br"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Desenvolvido por MTI
                </a>
              </div>
            </Col>
            {lists?.map((list) => {
              return (
                <Col
                  className="xvia-footer__card"
                  xs={24}
                  sm={11}
                  md={11}
                  lg={4}
                  xl={4}
                  xxl={4}
                  key={list.title}
                >
                  <LinkList title={list.title} linkList={list.linkList} />
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </div>
  );
};
