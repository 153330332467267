import React, { FC } from "react";

import { Col, Row } from "antd";
import { Container } from "../../components/container/container";
import { CategoryCard } from "../../components/cards/category-card";
import { BreakPage } from "../../components/break-page/break-page";

export interface OmbudsmanCardProps {
  text: string;
  icon: string;
  detail: string;
  onClick: () => void;
}

export interface OmbudsmanProps {
  itens?: OmbudsmanCardProps[];
}

export const Ombudsman: FC<OmbudsmanProps> = ({ itens }) => {
  return (
    <div className="xvia-ombudsman">
      <Container className="xvia-ombudsman__container">
        <BreakPage text={"CENTRAL DO CIDADÃO"} color={"#ECFF00"} />
        <h2>FALE COM O GOVERNO</h2>
        <Row justify="center" gutter={[15, 34]}>
          {itens?.map((i) => {
            return (
              <Col
                className="xvia-ombudsman__card"
                xs={24}
                sm={11}
                md={11}
                lg={4}
                xl={4}
                xxl={4}
                onClick={i.onClick}
              >
                <CategoryCard icon={i.icon} detail={i.text} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};
