import React, { FC } from "react";
import classNames from "classnames";
import { Title } from "../typography/title";

export interface SidebarCardProps {
  title?: string;
  icon?: string;
  className?: string;
}

export const SidebarCard: FC<SidebarCardProps> = ({ title, className = "", children }) => {
  const containerClassName = classNames({
    "xvia-card": true,
    [className]: !!className,
  });
  return (
    <div className={containerClassName}>
      {title && (
        <Title level={4} className={"xvia-card__title"}>
          <span>{"//"}</span> {title}
        </Title>
      )}
      {children}
    </div>
  );
};
