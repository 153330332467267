import React, { FC } from "react";

import { Button } from "antd";
import { NewsCard, NewsCardProps } from "../../components/cards/news-card";
import { Container } from "../../components/container/container";
import { Carrocel } from "../../components/carousel/carrocel";
import { BreakPage } from "../../components/break-page/break-page";

export interface NewsProps {
  news?: NewsCardProps[];
  clickMore?: () => any;
}

export const News: FC<NewsProps> = ({ news, clickMore }) => {
  return (
    <div className="xvia-news">
      <Container className="xvia-news__container">
        <BreakPage text={"ÚLTIMAS NOTÍCIAS"} color={"#34D5AA"} />
        <div>
          {/*<Row justify="center" gutter={[15, 34]}>*/}
          <Carrocel itemsLength={news?.length}>
            {news?.map((newsItem) => {
              return (
                <div className="xvia-news__card">
                  <NewsCard
                    title={newsItem.title}
                    onClick={newsItem.onClick}
                    imageUrl={newsItem.imageUrl}
                    categoryName={newsItem.categoryName}
                    date={newsItem.date}
                  />
                </div>
              );
            })}
          </Carrocel>
          {/*</Row>*/}
          <Button onClick={clickMore}>Ver mais</Button>
        </div>
      </Container>
    </div>
  );
};
